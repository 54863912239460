import { Auth } from "aws-amplify";
import { useMutation as useReactQueryMutation } from "@tanstack/react-query";
import { AxiosInstance, AxiosResponse } from "axios";

import { usePageLayoutContext } from "components/common/layout";

type useMutationProps<TData> = {
  url?: string;
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  onSuccess?: (data: TData) => void;
  onError?: (error: Error) => void;
  api: AxiosInstance;
  notifications?: {
    type: 'success' | 'error';
    content?: React.ReactNode;
    shouldAppendError?: boolean;
  }[];
};

const useMutation = <TResponseBody, TRequestBody = {}>
  ({ url, method, onSuccess, onError, api, notifications }: useMutationProps<AxiosResponse<TResponseBody>>) => {
  const { setNotification } = usePageLayoutContext();
  const { mutate, mutateAsync, data, error, status, isPending, isError, isSuccess } = useReactQueryMutation({
    throwOnError: false,
    mutationFn: async (data?: TRequestBody & { urlPath?: string; fullUrl?: string }) => {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      };

      const requestUrl = data?.fullUrl
        ? data.fullUrl
        : (url && url.endsWith('/') ? url.slice(0, -1) : url) + (data?.urlPath?.startsWith('/') ? data.urlPath : `/${data?.urlPath || ''}`);

      if (data?.urlPath) delete data.urlPath;
      if (data?.fullUrl) delete data.fullUrl;

      return api.request<TResponseBody>({
        method,
        url: requestUrl,
        data,
        headers,
      });
    },
    onSuccess: (data) => {
      showNotification('success');
      onSuccess?.(data);
    },
    onError: (error: any) => {
      error.message = error?.response?.data?.message ? 
        Array.isArray(error.response.data.message) ? error.response.data.message.join(', ') 
          : error.response.data.message : 'Bad request';
      const notification = notifications?.find(notification => notification.type === 'error');
      if (notification) {
        notification.content = notification.content || error.message
        if (notification.shouldAppendError) {
          notification.content += ' ' + error.message;
        }
      }
      showNotification('error');
      onError?.(error);
    }
  });

  const mutateAsyncWithErrorHandling = async (
    data?: TRequestBody & { urlPath?: string; fullUrl?: string }
  ): Promise<AxiosResponse<TResponseBody> | undefined>  => {
    try {
      const response = await mutateAsync(data);
      return response;
    } catch (error: any) {}
  }; 

  const showNotification = (type: 'success' | 'error') => {
    const notification = notifications?.find(notification => notification.type === type);
    if (notification) {
      setNotification([{
        type: type,
        content: notification.content,
      }]);
    }
  };

  return { mutate, mutateAsync: mutateAsyncWithErrorHandling, data, error, status, isPending, isError, isSuccess };
};

export default useMutation;