import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    CollectionPreferencesProps,
    CollectionPreferences,
    ButtonDropdown,
    SpaceBetween,
    TextFilter,
    Button,
    Header,
    Select,
    Table,
    TableProps,
    Modal,
} from '@cloudscape-design/components';

import { EmptyState } from 'components/empty-state/EmptyState';
import { useDeviceManagerContext } from 'pages/device-manager/DeviceListPage';
import useMutation from 'hooks/useMutation';
import useFetch from 'hooks/useFetch';
import { PaginationContent } from 'components/table-content';
import DisableModal from 'components/disable-modal';
import { Asset, DeviceState } from 'types/custom';
import DeleteModal from 'components/delete-modal';
import { deviceManagerAPI } from 'api';
import {
    API_URL_PATH_PHYSICAL_DEVICE_TYPES,
    API_URL_PATH_DEVICE_TYPES,
    API_URL_PATH_DM_DEVICE,
    URL_PATH_DEVICE_MANAGER_INSTALL_DEVICE,
} from 'constants/urls';
import { toTitleCase } from 'utils';
import useAuth from 'hooks/useAuth';

import DeviceTabs from '../DeviceTabs';
import UpdateDeviceModal from '../UpdateDeviceModal';
import { buttonDropdownItems, getColumnDefinitions, getContentDisplay, getContentDisplayPreferences } from './table-config';

type DeviceTableProps = {
    showActions?: boolean;
    showDeviceModalEnabled?: boolean;
    selectionType?: TableProps.SelectionType;
}

const DeviceTable = ({
    showActions,
    selectionType,
    showDeviceModalEnabled = false,
}: DeviceTableProps) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDisableModal, setShowDisableModal] = useState(false);
    const [showDeviceDetailsModal, setShowDeviceDetailsModal] = useState(false);
    const [initialSelectionMade, setInitialSelectionMade] = useState(false);
    const [physicalDeviceTypeOptions, setPhysicalDeviceTypeOptions] = useState<OptionDefinition[]>([{ label: 'All', value: '' }]);
    const [deviceTypeOptions, setDeviceTypeOptions] = useState<OptionDefinition[]>([{ label: 'All', value: '' }]);
    const [deviceStateOptions, setDeviceStateOptions] = useState<OptionDefinition[]>([{ label: 'All', value: '' }]);
    const onDeleteDiscard = () => setShowDeleteModal(false);

    const {
        allDevices,
        setSelectedDevices,
        selectedDevices,
        getAllDevices,
        deviceFetching,
        deviceLoading,
        syncDevices,
        isSyncingDevices,
        countAlertRules,
    } = useDeviceManagerContext();

    const contentDisplay = getContentDisplay(!!countAlertRules);
    const contentDisplayPreference = getContentDisplayPreferences(!!countAlertRules);
    const [tablePreferences, setTablePreferences] = useState<CollectionPreferencesProps.Preferences>(localStorage.getItem('deviceTablePreferences') ? JSON.parse(localStorage.getItem('deviceTablePreferences')!) : {
        pageSize: 15,
        wrapLines: true,
        stickyColumns: { first: 0 },
        contentDisplay,
    });

    const { roles } = useAuth();

    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const onUpdateDiscard = () => setShowUpdateModal(false);

    const navigate = useNavigate();

    const { data: deviceTypesData } = useFetch<string[]>({
        axiosInstance: deviceManagerAPI,
        key: 'device-types',
        url: `${API_URL_PATH_DEVICE_TYPES}`,
    });

    const { data: physicalDeviceTypesData } = useFetch<string[]>({
        axiosInstance: deviceManagerAPI,
        key: 'physical-device-types',
        url: `${API_URL_PATH_PHYSICAL_DEVICE_TYPES}`,
    });

    useEffect(() => {
        if (deviceTypesData) {
            setDeviceTypeOptions([
                { label: 'All', value: '' },
                ...deviceTypesData.map((deviceType: string) =>
                    ({ label: deviceType, value: deviceType }))
            ]);
        }
        if (physicalDeviceTypesData) {
            setPhysicalDeviceTypeOptions([
                { label: 'All', value: '' },
                ...physicalDeviceTypesData.map((physicalDeviceType: string) =>
                    ({ label: physicalDeviceType, value: physicalDeviceType }))
            ]);
        }
        setDeviceStateOptions([
            { label: 'All', value: '' },
            ...Object.keys(DeviceState).map((deviceState: string) =>
                ({ label: toTitleCase(deviceState), value: deviceState }))
        ]);
    }, [deviceTypesData, physicalDeviceTypesData]);

    const [selectedDeviceType, setSelectedDeviceType] = useState<OptionDefinition>({ label: 'All', value: '' });
    const [selectedPhysicalDeviceType, setSelectedPhysicalDeviceType] = useState<OptionDefinition>({ label: 'All', value: '' });
    const [selectedDeviceState, setSelectedDeviceState] = useState<OptionDefinition>({ label: 'All', value: '' });

    const filteredDevices: Asset[] = useMemo(() => {
        return allDevices.filter((device) => {
            const deviceTypeMatch = selectedDeviceType.value === '' || device.deviceTypeId === selectedDeviceType.value;
            const physicalDeviceTypeMatch = selectedPhysicalDeviceType.value === '' || device.physicalDeviceId === selectedPhysicalDeviceType.value;
            const deviceStateMatch = selectedDeviceState.value === '' || device.deviceState === selectedDeviceState.value;

            return deviceTypeMatch && physicalDeviceTypeMatch && deviceStateMatch;
        });
    }, [allDevices, selectedDeviceState, selectedDeviceType, selectedPhysicalDeviceType])

    const {
        items,
        actions,
        collectionProps,
        filterProps,
        paginationProps,
    } = useCollection(filteredDevices, {
        filtering: {
            empty: (
              <EmptyState
                    title="No devices"
                    subtitle="No devices to display."
                />
            ),
            noMatch: (
              <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={
                      <Button onClick={() => actions.setFiltering('')}>
                        Clear filter
                      </Button>
                    }
                />
            ),
        },
        pagination: { pageSize: tablePreferences.pageSize },
        sorting: {},
    });

    const { mutate: mutateDisableDevices, isPending: isDisableDevicesPending } = useMutation<string[], {
        ids: string[]
    }>({
        url: `${API_URL_PATH_DM_DEVICE}/disable`,
        method: 'PATCH',
        api: deviceManagerAPI,
        notifications: [
            {type: 'success', content: `Disabled ${selectedDevices!.length} devices successfully`},
            {type: 'error' }
        ],
        onSuccess: () => {
            getAllDevices();
            setSelectedDevices([]);
        },
    });

    const {
        mutate: deleteDevice,
        isPending: isDeleteDevicesPending,
    } = useMutation<void, undefined>({
        api: deviceManagerAPI,
        method: 'DELETE',
        url: `${API_URL_PATH_DM_DEVICE}/${selectedDevices?.[0]?.name || ''}`,
        notifications: [
            {type: 'success', content: `Deleted device ${selectedDevices![0]?.name} successfully`},
            {type: 'error' }
        ],
        onSuccess: () => {
            getAllDevices();
            setSelectedDevices([]);
        },
        onError: () => {
            getAllDevices();
            setSelectedDevices([]);
        },
    });

    const onDeviceSelected = (devices: Asset[]) => {
        setSelectedDevices([...devices]);
        setShowDeviceDetailsModal(true)
    };

    const onDeleteConfirm = () => {
        deleteDevice(undefined);
        setShowDeleteModal(false);
    };

    const handleButtonDropdownClick = (event: any) => {
        event.preventDefault();

        if (event.detail.id === 'disable') {
            setShowDisableModal(true);
        } else if (event.detail.id === 'delete') {
            setShowDeleteModal(true);
        } else if (event.detail.id === 'edit') {
            setShowUpdateModal(true);
        } else if (event.detail.id === 'install') {
            navigate(
                URL_PATH_DEVICE_MANAGER_INSTALL_DEVICE,
                {
                    state: {
                        flowType: 'install',
                        selectedDevices: selectedDevices.map(({ name, friendlyName }) => ({ name, friendlyName })),
                    },
                }
            );
        }
    };

    const onDisableConfirm = () => {
        const ids = selectedDevices!.map((device) => device.name);
        mutateDisableDevices({ ids });
        setShowDisableModal(false);
    };

    const refreshTable = async () => {
        const response = await getAllDevices();
        if (response?.data) {
            setSelectedDevices(response?.data?.filter((item) =>
                selectedDevices.map((device) => device.name).includes(item.name)) || []);
        }
    }

    useEffect(() => {
        getAllDevices();
        setSelectedDevices([]);
    }, []);

    return (
      <>
        <Table
                {...collectionProps}
                onSelectionChange={({ detail }) => {
                    setSelectedDevices(detail.selectedItems);
                    if (!initialSelectionMade) {
                        setInitialSelectionMade(true);
                    }
                }}
                selectedItems={selectedDevices}
                loading={
                    deviceLoading ||
                    isDisableDevicesPending ||
                isDeleteDevicesPending
                }
                wrapLines={tablePreferences.wrapLines}
                stickyHeader
                contentDensity={tablePreferences.contentDensity}
                stripedRows
                columnDefinitions={
                    getColumnDefinitions(!!countAlertRules, onDeviceSelected)
                }
                columnDisplay={tablePreferences.contentDisplay}
                stickyColumns={tablePreferences.stickyColumns}
                items={items}
                variant='container'
                loadingText='Loading resources'
                selectionType={selectionType}
                trackBy={(item) => item.name}
                filter={
                  <SpaceBetween size='xs' direction='horizontal' alignItems='end'>
                    <div style={{ width: '300px' }}>
                      <TextFilter
                                {...filterProps}
                                filteringPlaceholder='Find by friendly name and location'
                            />
                    </div>
                    <div style={{ minWidth: '100px' }}>
                      <Select
                                inlineLabelText='Device Type'
                                options={deviceTypeOptions}
                                selectedOption={selectedDeviceType}
                                onChange={(event) => { setSelectedDeviceType(event.detail.selectedOption) }}
                            />
                    </div>
                    <div style={{ minWidth: '130px' }}>
                      <Select
                                inlineLabelText='Physical Device ID'
                                options={physicalDeviceTypeOptions}
                                selectedOption={selectedPhysicalDeviceType}
                                onChange={(event) => setSelectedPhysicalDeviceType(event.detail.selectedOption)}
                            />
                    </div>
                    <div>
                      <Select
                                inlineLabelText='State'
                                options={deviceStateOptions}
                                selectedOption={selectedDeviceState}
                                onChange={(event) => setSelectedDeviceState(event.detail.selectedOption)}
                            />
                    </div>
                  </SpaceBetween>
                }
                header={
                  <Header
                        counter={
                            selectedDevices?.length && selectedDevices[0]?.name
                                ? '(' +
                                selectedDevices.length +
                                `/${allDevices && allDevices.length})`
                                : `(${allDevices && allDevices.length})`
                        }
                        actions={
                          <SpaceBetween direction='horizontal' size='xs'>
                            <Button iconName='refresh' onClick={refreshTable}
                                    loading={deviceFetching} disabled={isSyncingDevices} />
                            {showActions && <>
                              {(roles as string[])?.includes('claim:sensors') && <Button onClick={async () => {
                                        await syncDevices();
                                        await refreshTable();
                              }} loading={isSyncingDevices}>Sync</Button>}

                              <ButtonDropdown
                                        items={buttonDropdownItems(selectedDevices)}
                                        onItemClick={handleButtonDropdownClick}
                                    >
                                Actions
                              </ButtonDropdown>
                            </>
                                }
                          </SpaceBetween>
                        }
                    >
                    Devices
                  </Header>
                }
                preferences={
                  <CollectionPreferences
                        title='Table Preferences'
                        confirmLabel='Apply'
                        cancelLabel="Cancel"
                        preferences={tablePreferences}
                        wrapLinesPreference={{
                            label: 'Wrap lines',
                            description: 'Select to see all the text and wrap the lines',
                        }}
                        contentDensityPreference={{
                            label: 'Compact mode',
                            description: 'Select to display content in a denser, more compact mode',
                        }}
                        pageSizePreference={{
                            title: "Items per page",
                            options: [
                                { value: 15, label: "15 devices" },
                                { value: 30, label: "30 devices" },
                                { value: 50, label: "50 devices" },
                                { value: 100, label: "100 devices" },
                            ]
                        }}
                        contentDisplayPreference={{
                            options: contentDisplayPreference,
                            title: "Visible columns",
                            description: "Select columns to display in the table"
                        }}
                        stickyColumnsPreference={{
                            firstColumns: {
                                title: "Stick first column(s)",
                                description:
                                    "Keep the first column(s) visible while horizontally scrolling the table content.",
                                options: [
                                    { label: "None", value: 0 },
                                    { label: "First column", value: 1 },
                                    { label: "First two columns", value: 2 }
                                ]
                            }
                        }}
                        onConfirm={({ detail }) => {
                            setTablePreferences(detail);
                            localStorage.setItem('deviceTablePreferences', JSON.stringify(detail));
                        }}
                    />
                }
                pagination={
                  <PaginationContent paginationProps={paginationProps} />
                }
            />

        {showDeviceModalEnabled
                && selectedDevices.length === 1 && (
                  <Modal
                        onDismiss={() => setShowDeviceDetailsModal(false)}
                        size="large"
                        header={selectedDevices?.[0]?.friendlyName}
                        visible={showDeviceDetailsModal}>
                    <DeviceTabs selectedDevices={[selectedDevices[0]]} isInModal={true} initialTabId='alert-rules' />
                  </Modal>
                )}

        {showUpdateModal && (
          <UpdateDeviceModal
                    selectedDevice={selectedDevices}
                    visible={showUpdateModal}
                    onDiscard={onUpdateDiscard}
                    refetch={getAllDevices}
                />
            )}

        <DisableModal
                visible={showDisableModal}
                onDiscard={() => setShowDisableModal(false)}
                onDisable={onDisableConfirm}
                itemName={selectedDevices}
                moduleName='Device'
            />

        <DeleteModal
                visible={showDeleteModal}
                onDiscard={onDeleteDiscard}
                onDelete={onDeleteConfirm}
                itemName={selectedDevices}
                itemCount={selectedDevices?.length}
                moduleName='Device'
            />
      </>
    );
};

export default DeviceTable;
