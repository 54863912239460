import {
    Box,
    Header,
    SpaceBetween,
    Table,
    Button,
    Alert,
    Spinner,
    Link,
    SelectProps,
    Select,
    TextFilter,
} from '@cloudscape-design/components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';

import {
    API_URL_PATH_DM,
    API_URL_PATH_IM_INCIDENTS,
    API_URL_PATH_IM_PLANS,
} from 'constants/urls';
import { deviceManagerAPI, incidentManagerAPI } from 'api';
import { PaginationContent } from 'components/table-content';
import { EmptyState } from 'components/empty-state/EmptyState';
import useFetch from 'hooks/useFetch';
import { snakeCaseToNormalCase } from 'components/device-card/utils';
import { thresholdCodeToName, toTitleCase } from 'utils';
import IncidentStatusBadge from 'components/incident-status/IncidentStatusBadge';
import { AlertRule, Asset, EscalationPlan, Incident, IncidentStatus } from 'types/custom';

import IncidentDetailsModal from '../IncidentDetailsModal';
import IncidentAddActivity from '../IncidentAddActivity';
import { getLastEvent } from './utils';

type IncidentsTableProps = {
    variant: 'embedded' | 'container';
    allDevices: Asset[];
    selectedDeviceProp?: Asset;
    allMeasures: SelectProps.Options;
};

const IncidentsTable = ({ variant, allDevices, selectedDeviceProp, allMeasures }: IncidentsTableProps) => {
    const isTableInIncidentManagerPage = variant === 'container';

    const [allIncidents, setAllIncidents] = useState<Incident[]>([]);
    const [selectedIncidents, setSelectedIncidents] = useState<Incident[]>([]);
    const [showIncidentDetailsModal, setShowIncidentDetailsModal] = useState(false);
    const [selectedMeasure, setSelectedMeasure] = useState<OptionDefinition>({ value: '', label: 'All' });
    const [selectedStatus, setSelectedStatus] = useState<OptionDefinition>({ value: '', label: 'All' });

    const [deviceOptions, setDeviceOptions] = useState<SelectProps.Options>([{ value: '', label: 'All' }]);
    const [selectedDeviceOption, setSelectedDeviceOption] = useState<OptionDefinition>(
        selectedDeviceProp ?
            { value: selectedDeviceProp.name, label: selectedDeviceProp.name } :
            deviceOptions[0]
    );

    useEffect(() => {
        if (allDevices) {
            setDeviceOptions([
                { value: '', label: 'All' },
                ...allDevices.map<OptionDefinition>((device) => ({
                    value: device.name,
                    label: device.friendlyName,
                    labelTag: device.name,
                    description: device.description,
                }))
            ]);
        }
    }, [allDevices]);

    const {
        data: incidentsResponse,
        error: incidentsError,
        isLoading: incidentsLoading,
        isFetching: incidentsFetching,
        status: incidentsStatus,
        refetch: refetchIncidents
    } = useFetch<{ items: Incident[] }>({
        key: `incidents-${selectedDeviceOption.value}`,
        axiosInstance: incidentManagerAPI,
        url: `${API_URL_PATH_IM_INCIDENTS}${'?resourceId=' + selectedDeviceOption.value}`,
    });

    const {
        data: rulesResponse,
        error: rulesError,
        status: rulesStatus,
        isFetching: rulesFetching,
        refetch: refetchRules,
    } = useFetch<AlertRule[]>({
        axiosInstance: deviceManagerAPI,
        url: `${API_URL_PATH_DM}/allRules`,
        key: `rules`,
    });

    const {
        data: plansList,
        isFetching: plansFetching,
        refetch: refetchPlans,
    } = useFetch<{ items: EscalationPlan[] }>({
        axiosInstance: incidentManagerAPI,
        url: API_URL_PATH_IM_PLANS,
        key: 'escalation-plans',
    });

    useEffect(() => {
        if (incidentsStatus === 'success') {
            setAllIncidents(incidentsResponse?.items);
        }
    }, [incidentsResponse?.items, incidentsStatus]);

    const getRule = useCallback((item: Incident) => rulesStatus === 'success' ?
        (rulesResponse || []).find((rule) => rule.id === item.alertRule)
        : undefined, [rulesResponse, rulesStatus]);

    const {
        items,
        actions,
        filterProps,
        collectionProps,
        paginationProps,
        filteredItemsCount,
    } = useCollection(allIncidents, {
        filtering: {
            filteringFunction(item, filteringText) {
                const itemFriendlyName = allDevices?.find((device) => device.name === item.resourceId)?.friendlyName;

                if (item.resourceId.toLowerCase().includes(filteringText.toLowerCase())) return true;
                if (itemFriendlyName?.toLowerCase().includes(filteringText.toLowerCase())) return true;
                if (getRule(item)?.threshold_value.toString().includes(filteringText)) return true;
                const thresholdCodeName = thresholdCodeToName(getRule(item)?.threshold_code)?.toLowerCase();
                if (thresholdCodeName?.includes(filteringText.toLowerCase())) return true;
                if (getLastEvent(item)?.toLowerCase().includes(filteringText.toLowerCase())) return true;

                if (filteringText === '') return true;

                return false;
            },
            empty: (
              <EmptyState
                    title="No incidents"
                    subtitle="No incidents to display."
                />
            ),
            noMatch: (
              <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={
                      <Button onClick={() => actions.setFiltering('')}>
                        Clear filter
                      </Button>
                    }
                />
            )
        },
        pagination: { pageSize: 20 },
        sorting: {},
    });

    const columns = useMemo(() => {
        const failoverColumn = (item: Incident) => rulesStatus === 'success' && !getRule(item) ? '-' : <Spinner />;

        return isTableInIncidentManagerPage ? [{
            id: 'createdAt',
            header: 'Started',
            cell: (item: Incident) => (<Link onFollow={() => {
                setSelectedIncidents([item])
                setShowIncidentDetailsModal(true)
            }}>
              {item.createdAt ? new Date(item.createdAt * 1000).toLocaleString() : '-'}
            </Link>),
            sortingField: 'createdAt',
        },
        {
            id: 'status',
            header: 'Status',
            cell: (item: Incident) => (<IncidentStatusBadge status={item.status} />),
            sortingField: 'status',
        },
        {
            id: 'device Id',
            header: 'Name',
            cell: (item: Incident) => item.resourceId,
            sortingField: 'resourceId',
        }, {
            id: 'friendly-name',
            header: 'Friendly Name',
            cell: (item: Incident) => item.friendlyName,
            sortingField: 'friendlyName',
        },
        {
            id: 'measure',
            header: 'Measure',
            cell: (item: Incident) => snakeCaseToNormalCase(getRule(item)?.measure || '') ?? failoverColumn(item),
            sortingField: 'measure',
        },
        {
            id: 'type',
            header: 'Type',
            cell: (item: Incident) => thresholdCodeToName(getRule(item)?.threshold_code) ?? failoverColumn(item),
            sortingField: 'threshold_code',
        },
        {
            id: 'threshold',
            header: 'Threshold',
            cell: (item: Incident) => getRule(item)?.threshold_value ?? failoverColumn(item),
            sortingField: 'threshold_value',
        },
        {
            id: 'lastEvent',
            header: 'Last Event',
            cell: (item: Incident) => getLastEvent(item),
            sortingField: 'lastEvent',
        }] : [{
            id: 'createdAt',
            header: 'Started',
            cell: (item: Incident) => (<Link onFollow={() => {
                setSelectedIncidents([item])
                setShowIncidentDetailsModal(true)
            }}>
              {item.createdAt ? new Date(item.createdAt * 1000).toLocaleString() : '-'}
            </Link>),
            sortingField: 'createdAt',
        },
        {
            id: 'status',
            header: 'Status',
            cell: (item: Incident) => (<IncidentStatusBadge status={item.status} />),
            sortingField: 'status',
        },
        {
            id: 'measure',
            header: 'Measure',
            cell: (item: Incident) => snakeCaseToNormalCase(getRule(item)?.measure || '') ?? failoverColumn(item),
            sortingField: 'measure',
        },
        {
            id: 'type',
            header: 'Type',
            cell: (item: Incident) => thresholdCodeToName(getRule(item)?.threshold_code) ?? failoverColumn(item),
            sortingField: 'threshold_code',
        },
        {
            id: 'threshold',
            header: 'Threshold',
            cell: (item: Incident) => getRule(item)?.threshold_value ?? failoverColumn(item),
            sortingField: 'threshold_value',
        },
        {
            id: 'lastEvent',
            header: 'Last Event',
            cell: (item: Incident) => getLastEvent(item),
            sortingField: 'lastEvent',
        }];
    }, [allDevices, getRule, isTableInIncidentManagerPage, rulesStatus]);

    if (incidentsError) return <Alert type='error' header='Error loading alert rules'>{incidentsError.toString()}</Alert>;
    if (rulesError) return <Alert type='error' header='Error loading alert rules'>{rulesError.toString()}</Alert>;

    return (
      <>
        <Table
                {...collectionProps}
                onSelectionChange={({ detail }) =>
                    setSelectedIncidents(detail.selectedItems)
                }
                variant={variant}
                selectedItems={selectedIncidents}
                columnDefinitions={columns}
                items={items}
                loadingText='Loading resources'
                loading={incidentsLoading}
                selectionType='multi'
                trackBy={(item) => item.id}
                stripedRows
                empty={
                  <Box textAlign='center' color='inherit'>
                    <Box
                            padding={{ bottom: 's' }}
                            variant='p'
                            color='inherit'
                        >
                      No incidents to display.
                    </Box>
                  </Box>
                }
                pagination={
                  <PaginationContent paginationProps={paginationProps} />
                }
                filter={
                  <SpaceBetween direction='horizontal' size='xs' alignItems='end'>
                    <div style={{ width: '300px' }}>
                      <TextFilter
                                {...filterProps}
                                filteringPlaceholder='Filter incidents'
                            />
                    </div>
                    <div style={{ minWidth: '125px' }}>
                      <Select
                                inlineLabelText="Filter status"
                                selectedOption={selectedStatus}
                                disabled={incidentsLoading}
                                onChange={({ detail }) => {
                                    setSelectedStatus(detail.selectedOption);
                                    setAllIncidents(incidentsResponse?.items.filter((incident) => detail.selectedOption.value === '' || incident.status === detail.selectedOption.value));
                                }}
                                loadingText='Loading'
                                options={[
                                    { value: '', label: 'All' },
                                    ...Object.keys(IncidentStatus).map((status) =>
                                        ({ value: status, label: toTitleCase(status) })),
                                ]}
                            />
                    </div>
                    <div style={{ minWidth: '125px' }}>
                      <Select
                                inlineLabelText="Filter measure"
                                selectedOption={selectedMeasure}
                                disabled={incidentsLoading}
                                onChange={({ detail }) => {
                                    setSelectedMeasure(detail.selectedOption);
                                    setAllIncidents(incidentsResponse?.items.filter((incident) => detail.selectedOption.value === '' || getRule(incident)?.measure === detail.selectedOption.value));
                                }}
                                loadingText='Loading'
                                options={allMeasures}
                                empty='No options'
                            />
                    </div>
                    {isTableInIncidentManagerPage && <div style={{ minWidth: '200px' }}>
                      <Select
                                inlineLabelText="Filter device"
                                selectedOption={selectedDeviceOption}
                                onChange={({ detail }) => {
                                    setSelectedDeviceOption(detail.selectedOption);
                                    setSelectedMeasure({ value: '', label: 'All' });
                                    setSelectedStatus({ value: '', label: 'All' });
                                    refetchIncidents();
                                }}
                                loadingText='Loading'
                                filteringType='auto'
                                options={deviceOptions}
                                empty='No options'
                            />
                    </div>}
                  </SpaceBetween>
                }
                header={
                  <Header
                        counter={selectedIncidents.length > 0
                            ? `(${selectedIncidents.length}/${filteredItemsCount})`
                            : `(${filteredItemsCount})`}
                        actions={
                          <SpaceBetween direction='horizontal' size='xs'>
                            <Button iconName='refresh' loading={incidentsFetching || rulesFetching || plansFetching} onClick={() => {
                                    refetchRules();
                                    refetchPlans();
                                    refetchIncidents();
                            }} />
                            <IncidentAddActivity
                                    incidents={selectedIncidents}
                                    refetchIncidents={refetchIncidents}
                                    loading={incidentsFetching} />
                            <Button onClick={() => setShowIncidentDetailsModal(true)}
                                    disabled={selectedIncidents.length !== 1}>Details</Button>
                          </SpaceBetween>
                        }
                    >
                    Incidents
                  </Header>
                }
            />
        {showIncidentDetailsModal && (
          <IncidentDetailsModal
                    visible={showIncidentDetailsModal}
                    setVisible={setShowIncidentDetailsModal}
                    incident={selectedIncidents[0]}
                    alertRule={getRule(selectedIncidents[0])!}
                    escalationStages={plansList?.items?.
                        find((plan) => plan.name === getRule(selectedIncidents[0])?.escalation_plan)?.stages}
                    refetchIncidents={refetchIncidents}
                    loading={incidentsFetching}
                />
            )}
      </>
    );
};

export default IncidentsTable;
