import {
    Alert,
    Box,
    Button,
    ButtonDropdown,
    ButtonDropdownProps,
    Header,
    Link,
    Modal,
    SpaceBetween,
    Table,
    Toggle,
} from '@cloudscape-design/components';
import { useEffect, useState } from 'react';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { Divider } from '@mui/material';
import { ClickDetail } from '@cloudscape-design/components/internal/events';

import { API_URL_PATH_DM_RULE, API_URL_PATH_IM_PLANS } from 'constants/urls';
import { deviceManagerAPI, incidentManagerAPI } from 'api';
import { AlertRule, Asset, AssetAlertRuleStatus, EscalationPlan } from 'types/custom';
import { snakeCaseToNormalCase } from 'components/device-card/utils';
import useFetch from 'hooks/useFetch';
import { thresholdCodeToName } from 'utils';
import { secondsToRelative, relativeToText } from 'utils/relativeTime';
import CreateDeviceGroup from 'components/device-group-manager/CreateDeviceGroup';
import { useAlertRulesManagerContext } from 'providers/AlertRulesManagerProvider';
import DeleteModal from 'components/delete-modal';
import useMutation from 'hooks/useMutation';
import { useAlertRulesTableContext } from 'providers/AlertRulesTableProvider';

import AlertDetailsModal from '../AlertDetailsModal';
import CreateAlertModal from '../CreateAlertModal';

type AlertsTableType = {
    selectedDevices: Asset[]
    loading?: boolean,
    measurements: string[];
    isOnDeviceManagerPage?: boolean
    isInModal?: boolean,
}

const AlertsTable = ({
    loading: parentLoading,
    selectedDevices,
    measurements,
    isOnDeviceManagerPage = false,
    isInModal = false,
}: AlertsTableType) => {
    const { selectedGroup } = useAlertRulesManagerContext();
    const [selectedDeviceRules, setSelectedDeviceRules] = useState<AlertRule[]>([]);
    const [selectedGroupRule, setSelectedGroupRule] = useState<AlertRule[]>([]);

    const [isEditMode, setIsEditMode] = useState(false);
    const [showAlertDetailsModal, setShowAlertDetailsModal] = useState(false);
    const [showGroupAlertDetailsModal, setShowGroupAlertDetailsModal] = useState(false);
    const [showCreateAlertModal, setShowCreateAlertModal] = useState(false);
    const [showCreateDeviceGroupModal, setShowCreateDeviceGroupModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [disableAllToggles, setDisableAllToggles] = useState(false);
    const onDeleteDiscard = () => setShowDeleteModal(false);

    const isCreateButtonEnabled = (selectedDevices.length > 0 && measurements.length > 0) || selectedGroup;
    const shouldRefetchAlertRules = selectedGroup || isOnDeviceManagerPage;

    const [allEscalationPlans, setAllEscalationPlans] = useState<OptionDefinition[]>([]);

    const {
        rulesRefetch,
        referringCombo,
        items,
        collectionProps,
        groupSpecificItems,
        groupSpecificCollectionProps,
        rulesError,
        rulesLoading,
        rulesFetching,
        thresholdsOverlaps,
    } = useAlertRulesTableContext()

    const { data: plansList,
        isFetching: plansFetching,
        refetch: plansRefetch
    } = useFetch<{ items: EscalationPlan[] }>({
        axiosInstance: incidentManagerAPI,
        url: API_URL_PATH_IM_PLANS,
        key: 'escalation-plans',
    });

    useEffect(() => {
        if (plansList) setAllEscalationPlans([
            {
                label: 'None',
                value: '',
                description: 'No notifications',
            },
            ...plansList?.items?.map((plan) => ({
                label: plan.name,
                value: plan.name,
                disabled: !plan.active,
                disabledReason: 'Activate this plan to use it',
                description: plan.description,
            })) || [],
        ]);
    }, [plansList]);

    useEffect(() => {
        setSelectedDeviceRules([]);
    }, [selectedGroup]);

    useEffect(() => {
        // when device details popup gets shown, we need to refetch the rules for the changed device
        if (selectedDevices.length === 1 && isInModal) {
            rulesRefetch();
        }
    }, [selectedDevices, isInModal, rulesRefetch]);

    const { mutate: deleteAlertRules, isPending: isDeletingBulk } = useMutation<AlertRule[], { alertRuleIds: string[] }>({
        url: `${API_URL_PATH_DM_RULE}`,
        method: 'DELETE',
        api: deviceManagerAPI,
        notifications: [
            {type: 'success', content: `Deleted alert rule${selectedDeviceRules.length > 1 ? 's' : ''} for ${referringCombo} successfully`},
            {type: 'error'}
        ],
        onSuccess: () => {
            rulesRefetch();
            setSelectedDeviceRules([]);
            setShowDeleteModal(false);
        },
    });

    const onDeleteConfirm = () => {
        const ruleIds = selectedDeviceRules
            .map((rule) => rule.id ? rule.id : '')
            .filter((ruleId) => ruleId !== '');
        deleteAlertRules({ alertRuleIds: ruleIds });
    };

    const handleButtonDropdownClick = (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
        event.preventDefault();
        setIsEditMode(false);

        if (event.detail.id === 'details') {
            setShowAlertDetailsModal(true);
        } else if (event.detail.id === 'edit') {
            setIsEditMode(true);
            setShowCreateAlertModal(true);
        } else if (event.detail.id === 'delete') {
            setShowDeleteModal(true)
        }
    };

    const handleCreateButtonClick = (event: CustomEvent<ClickDetail>) => {
        event.preventDefault();
        setIsEditMode(false);

        if (selectedDevices.length > 1) {
            setShowCreateDeviceGroupModal(true);
        }
        else {
            setShowCreateAlertModal(true);
        }
    }

    const {
        mutateAsync: updateStatusForAlertRule,
        error: updateStatusForAlertRuleError,
    } = useMutation<AlertRule, {
        status: AssetAlertRuleStatus,
    }>({
        api: deviceManagerAPI,
        method: 'PATCH',
        notifications: [
            {type: 'success', content: `Updated alert rule status for ${referringCombo} successfully`},
            {type: 'error'}
        ]
    });

    const handleRuleToggle = async (rule: AlertRule, checked: boolean) => {
        rule.status = checked ? AssetAlertRuleStatus.ENABLED : AssetAlertRuleStatus.DISABLED;
        setDisableAllToggles(true);
        await updateStatusForAlertRule({
            fullUrl: `${API_URL_PATH_DM_RULE}/${rule.id}/toggle`,
            status: rule.status,
        });
        if (updateStatusForAlertRuleError) {
            rule.status = checked ? AssetAlertRuleStatus.DISABLED : AssetAlertRuleStatus.ENABLED;
        }
        setDisableAllToggles(false);
        rulesRefetch();
    }

    const commonColumnDefinitions = [
        {
            id: 'threshold',
            header: 'Threshold',
            cell: (item: AlertRule) => item.threshold_value,
            sortingField: 'threshold_value',
        },
        {
            id: 'deadband',
            header: 'Deadband',
            cell: (item: AlertRule) => relativeToText(secondsToRelative(item.deadband_period), '') || (<i>Disabled</i>),
            sortingField: 'deadband_period',
        },
        {
            id: 'escalation-plan',
            header: 'Escalation Plan',
            cell: (item: AlertRule) => item.escalation_plan || (<i>None</i>),
            sortingField: 'escalation_plan',
        }
    ]

    const shouldLoad = ((selectedGroup || isOnDeviceManagerPage) && (parentLoading || rulesLoading || isDeletingBulk))
        || (isInModal && rulesFetching);

    const shouldRefresh = ((selectedGroup || isOnDeviceManagerPage) && (parentLoading || rulesLoading || rulesFetching))
        || (isInModal && rulesFetching);

    if (rulesError) return <Alert type='error' header='Error loading alert rules'>{rulesError.toString()}</Alert>;

    const headerCounter = selectedGroup && !isInModal
        ? `(${groupSpecificItems.length})`
        : `(${selectedDeviceRules.length ? `${selectedDeviceRules.length}/${items.length}` : `${items.length}`})`

    return (
      <>
        <Table
                {...collectionProps}
                onSelectionChange={({ detail }) =>
                    setSelectedDeviceRules(detail.selectedItems as AlertRule[])
                }
                variant='embedded'
                selectedItems={selectedDeviceRules}
                columnDefinitions={[
                    {
                        id: 'status',
                        header: 'Enabled',
                        cell: (item: AlertRule) => (<Toggle
                            checked={item.status === AssetAlertRuleStatus.ENABLED}
                            onChange={({ detail }) => handleRuleToggle(item, detail.checked)}
                            disabled={disableAllToggles} />),
                        sortingField: 'status',
                    },
                    {
                        id: 'measure',
                        header: 'Measure',
                        cell: (item: AlertRule) => (<Link onFollow={() => {
                            setSelectedDeviceRules([item]);
                            setShowAlertDetailsModal(true);
                        }}>{snakeCaseToNormalCase(item.measure)}</Link>),
                        sortingField: 'measure',
                    },
                    {
                        id: 'type',
                        header: 'Type',
                        cell: (item: AlertRule) => (<Link onFollow={() => {
                            setSelectedDeviceRules([item]);
                            setShowAlertDetailsModal(true);
                        }}>{thresholdCodeToName(item.threshold_code)}</Link>),
                        sortingField: 'threshold_code',
                    },
                    ...commonColumnDefinitions
                ]}
                items={(!(isOnDeviceManagerPage || isInModal) && groupSpecificItems) || items}
                loadingText='Loading alert rules'
                loading={shouldLoad}
                selectionType='multi'
                stripedRows
                empty={
                  <Box textAlign='center' color='inherit'>
                    <b>No alert rules</b>
                    <Box
                            padding={{ bottom: 's' }}
                            variant='p'
                            color='inherit'
                        >
                      No alert rules to display.
                    </Box>
                    <Button
                            onClick={(event) => handleCreateButtonClick(event)}
                            disabled={!isCreateButtonEnabled}
                            disabledReason='Selected devices have no measurements'>
                      Create Alert Rule
                    </Button>
                  </Box>
                }
                header={
                  <Header
                        counter={headerCounter}
                        actions={
                          <SpaceBetween direction='horizontal' size='xs'>
                            <Button
                                    iconName='refresh'
                                    onClick={() => { rulesRefetch(); plansRefetch(); }}
                                    loading={shouldRefresh}
                                />

                            <Button onClick={(event) => handleCreateButtonClick(event)}>
                              Create
                            </Button>
                            <ButtonDropdown
                                    items={[
                                        {
                                            text: 'Details',
                                            id: 'details',
                                            disabled: !selectedDeviceRules.length || selectedDeviceRules.length > 1,
                                        },
                                        {
                                            text: 'Edit',
                                            id: 'edit',
                                            disabled: !selectedDeviceRules.length,
                                        },
                                        {
                                            text: 'Delete',
                                            id: 'delete',
                                            disabled: !selectedDeviceRules.length,
                                        },
                                    ]}
                                    onItemClick={handleButtonDropdownClick}
                                >
                              Actions
                            </ButtonDropdown>
                          </SpaceBetween>
                        }
                    >
                    Alert Rules
                  </Header>
                }
            />

        {showAlertDetailsModal && (
          <AlertDetailsModal
                    visible={showAlertDetailsModal}
                    setVisible={setShowAlertDetailsModal}
                    alertRule={selectedDeviceRules[0]}
                />
            )}
        {showCreateDeviceGroupModal &&
          <Modal
                    size='medium'
                    onDismiss={() => setShowCreateDeviceGroupModal(false)}
                    visible={showCreateDeviceGroupModal}
                    header='Create a Device Group'>

            <CreateDeviceGroup
                        setShowCreateDeviceGroupModal={setShowCreateDeviceGroupModal}
                        setShowCreateAlertModal={setShowCreateAlertModal}
                        isDeviceSelectDisabled={true}
                    />
          </Modal>
            }
        {thresholdsOverlaps.length > 0 && !rulesLoading && !isDeletingBulk && (
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <Alert type='warning' header='Thresholds overlap'>
              The lowest value for high or high-high threshold is higher than the highest value for low or low-low threshold.
              Please adjust the thresholds to avoid unexpected results.
            </Alert>
          </Box>)
            }

        {showCreateAlertModal && selectedDevices && (
          <CreateAlertModal
                    visible={showCreateAlertModal}
                    setVisible={setShowCreateAlertModal}
                    rulesRefetch={shouldRefetchAlertRules ? rulesRefetch : () => { }}
                    escalationPlansList={allEscalationPlans}
                    group={selectedGroup}
                    device={selectedGroup && !isInModal ? undefined : selectedDevices[0]}
                    alertRules={selectedDeviceRules}
                    setAlertRules={setSelectedDeviceRules}
                    measurements={measurements}
                    isEditing={isEditMode}
                    isOnDeviceManagerPage={isOnDeviceManagerPage}
                />
            )}
        {showDeleteModal && (
          <DeleteModal
                    visible={showDeleteModal}
                    onDiscard={onDeleteDiscard}
                    onDelete={onDeleteConfirm}
                    loading={isDeletingBulk}
                    itemName={selectedDeviceRules!?.map((item) => {
                        return `measure: '${item.measure}' with threashold '${item.threshold_value}'`;
                    })}
                    itemCount={selectedDeviceRules?.length}
                    moduleName='Alert'
                />
            )}


        {isOnDeviceManagerPage && (
          <>
            <Divider />
            <Box margin={{ vertical: "xs" }}>
              <Table
                            {...groupSpecificCollectionProps}
                            variant='embedded'
                            columnDefinitions={[
                                {
                                    id: 'deviceGroupId',
                                    header: 'Device Group Id',
                                    cell: (item: AlertRule) => item.deviceGroupId,
                                    sortingField: 'deviceGroupId',
                                },
                                {
                                    id: 'measure',
                                    header: 'Measure',
                                    cell: (item: AlertRule) => (<Link onFollow={() => {
                                        setSelectedGroupRule([item]);
                                        setShowGroupAlertDetailsModal(true);
                                    }}>{snakeCaseToNormalCase(item.measure)}</Link>),
                                    sortingField: 'measure',
                                },
                                {
                                    id: 'type',
                                    header: 'Type',
                                    cell: (item: AlertRule) => (<Link onFollow={() => {
                                        setSelectedGroupRule([item]);
                                        setShowGroupAlertDetailsModal(true);
                                    }}>{thresholdCodeToName(item.threshold_code)}</Link>),
                                    sortingField: 'threshold_code',
                                },
                                ...commonColumnDefinitions
                            ]}
                            items={groupSpecificItems}
                            loadingText='Loading alert rules'
                            loading={parentLoading || rulesLoading}
                            stripedRows
                            empty={
                              <Box textAlign='center' color='inherit'>
                                <b>No inherited alert rules from groups</b>
                              </Box>
                            }
                            header={
                              <Header
                                    counter={`(${groupSpecificItems.length.toString()})`}
                                    actions={
                                      <SpaceBetween direction='horizontal' size='xs'>
                                        <Button
                                                iconName='refresh'
                                                onClick={() => { rulesRefetch(); plansRefetch(); }}
                                                loading={(rulesFetching || plansFetching)} />
                                        <ButtonDropdown
                                                disabled
                                                items={[
                                                    {
                                                        text: 'Create',
                                                        id: 'create',
                                                        disabled: !isCreateButtonEnabled,
                                                    },
                                                    {
                                                        text: 'Details',
                                                        id: 'details',
                                                        disabled: !selectedDeviceRules.length,
                                                    },
                                                    {
                                                        text: selectedDeviceRules.length > 1 ? 'Edit Bulk' : 'Edit',
                                                        id: 'edit',
                                                        disabled: !selectedDeviceRules.length,
                                                    },
                                                    {
                                                        text: 'Delete',
                                                        id: 'delete',
                                                        disabled: !selectedDeviceRules.length,
                                                    },
                                                ]}
                                                onItemClick={handleButtonDropdownClick}
                                            >
                                          Actions
                                        </ButtonDropdown>
                                      </SpaceBetween>
                                    }
                                >
                                Inherited Alerts from Groups
                              </Header>
                            }
                        />
            </Box>
          </>
            )}
        {showGroupAlertDetailsModal && (
          <AlertDetailsModal
                    visible={showGroupAlertDetailsModal}
                    setVisible={setShowGroupAlertDetailsModal}
                    alertRule={selectedGroupRule[0]}
                />
            )}
      </>
    );
};

export default AlertsTable;
