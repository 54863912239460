import {
  Alert,
  Badge,
  Button,
  ColumnLayout,
  DateRangePicker,
  DateRangePickerProps,
  ExpandableSection,
  Form,
  FormField,
  Input,
  Modal,
  Popover,
  Select,
  SpaceBetween,
  Textarea,
  Toggle,
} from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { useMemo, useState } from 'react';

import { deviceManagerAPI } from 'api';
import { snakeCaseToNormalCase } from 'components/device-card/utils';
import IncidentStatusBadge from 'components/incident-status/IncidentStatusBadge';
import { API_URL_PATH_DM_DEVICE, API_URL_PATH_DM_GROUP, API_URL_PATH_DM_RULE } from 'constants/urls';
import { AlertRule, Asset, AssetAlertRuleStatus, DeviceGroup, IncidentStatus, StateAlertConfigCode } from 'types/custom';
import { relativeToSeconds, secondsToRelative } from 'utils/relativeTime';
import { useAlertRulesManagerContext } from 'providers/AlertRulesManagerProvider';
import { toTitleCase } from 'utils';
import DeviceSelect from 'components/device-select';
import useMutation from 'hooks/useMutation';

import { ThresholdPicker } from './ThresholdPicker';
import AlertStatusIndicator from '../AlertStatusIndicator';

type CreateAlertModalProps = {
  visible: boolean;
  setVisible: (state: boolean) => void;
  rulesRefetch: () => void;
  escalationPlansList: OptionDefinition[];
  device?: Asset;
  group?: DeviceGroup,
  alertRules?: AlertRule[];
  setAlertRules?: React.Dispatch<React.SetStateAction<AlertRule[]>>;
  measurements: string[];
  isEditing: boolean;
  isOnDeviceManagerPage?: boolean,
};

const CreateAlertModal = ({
  visible,
  setVisible,
  rulesRefetch,
  escalationPlansList,
  device,
  group,
  setAlertRules,
  measurements,
  alertRules,
  isEditing,
  isOnDeviceManagerPage,
}: CreateAlertModalProps) => {
  const isBulkEdit = alertRules ? alertRules?.length > 1 && isEditing : false;
  const alertRule = alertRules?.[0];

  const { setSelectedDevices, refetchGroups, allDevices } = useAlertRulesManagerContext();
  const [specificDevicesSelected, setSpecificDevicesSelected] = useState<readonly OptionDefinition[]>([]);
  const [disableAllFields, setDisableAllFields] = useState(false);
  const [isEditingForSpecificDevices, setIsEditingForSpecificDevices] = useState(false);
  const [status, setStatus] = useState<AssetAlertRuleStatus | undefined>(
    isEditing && alertRule?.status ? alertRule.status : undefined);
  const [measureName, setMeasureName] = useState<OptionDefinition | null>(isEditing && alertRule?.measure ? {
    label: snakeCaseToNormalCase(alertRule.measure),
    value: alertRule.measure,
  } : null);
  const [thresholdCodeId, setThresholdCodeId] = useState<StateAlertConfigCode | undefined>
    (isEditing ? alertRule?.threshold_code : undefined);
  const [thresholdValue, setThresholdValue] = useState(isEditing && alertRule?.threshold_value ? alertRule.threshold_value?.toString() : '');
  const [deadbandValue, setDeadbandValue] = useState<DateRangePickerProps.Value | null>(
    secondsToRelative(isEditing ? alertRule?.deadband_period : 0, true));
  const [monitoringForward, setMonitoringForward] = useState(isEditing && alertRule?.forward ? alertRule?.forward : false);
  const [selectedEscalationPlan, setSelectedEscalationPlan] = useState<OptionDefinition>({
    label: isEditing ? alertRule?.escalation_plan : 'None',
    value: isEditing ? alertRule?.escalation_plan : '',
  });

  const [notifOpen, setNotifOpen] = useState<DateRangePickerProps.Value | null>(
    secondsToRelative(isEditing ? alertRule?.notification_frequency?.OPEN : 0));
  const [notifAcknowledged, setNotifAcknowledged] = useState<DateRangePickerProps.Value | null>(
    secondsToRelative(isEditing ? alertRule?.notification_frequency?.ACKNOWLEDGED : 0));
  const [notifClearance, setNotifClearance] = useState<DateRangePickerProps.Value | null>(
    secondsToRelative(isEditing ? alertRule?.notification_frequency?.CLEARED : 0));
  const [notesValue, setNotesValue] = useState(isEditing && alertRule?.notes ? alertRule.notes : '');

  let url = `${API_URL_PATH_DM_RULE}/${alertRule?.id}`;

  if (!isEditing && group) {
    url = `${API_URL_PATH_DM_GROUP}/${group.groupId}/rules`;
  }
  if (!isEditing && device) {
    url = `${API_URL_PATH_DM_DEVICE}/${device.name}/rules`;
  }
  if (isBulkEdit && !isEditingForSpecificDevices) {
    url = `${API_URL_PATH_DM_RULE}/bulk?ids=${alertRules?.map(rule => rule.id).join(',')}`;
  }
  if (isEditingForSpecificDevices) {
    url = `${API_URL_PATH_DM_RULE}/bulk?ids=${alertRules?.map(rule => rule.id).join(',')}&deviceId=${specificDevicesSelected[0]?.value}&groupId=${group?.groupId}`;
  }

  const referringEntity = group && !device ? 'group' : 'device';
  const referringName = group && !device ? group.groupId : device?.name;
  const notificationFrequencyProps: DateRangePickerProps = useMemo(() => ({
    relativeOptions: [
      {
        key: "0-second",
        amount: 0,
        unit: "second",
        type: "relative"
      },
      {
        key: "30-minute",
        amount: 30,
        unit: "minute",
        type: "relative"
      },
      {
        key: "6-hour",
        amount: 6,
        unit: "hour",
        type: "relative"
      },
      {
        key: "1-day",
        amount: 1,
        unit: "day",
        type: "relative"
      }
    ],
    i18nStrings: {
      customRelativeRangeDurationLabel: 'Duration',
      customRelativeRangeDurationPlaceholder: 'Enter duration',
      customRelativeRangeOptionLabel: 'Custom interval',
      customRelativeRangeUnitLabel: 'Unit of time',
      formatRelativeRange: (value) => {
        const n = 1 === value.amount ? value.unit : `${value.unit}s`;
        return value.amount ? `${value.amount} ${n}` : 'Never skip';
      },
      formatUnit: (e, n) => (1 === n ? e : `${e}s`),
      relativeRangeSelectionHeading: 'No new notifications for',
      clearButtonLabel: 'Always skip',
      cancelButtonLabel: 'Cancel',
      applyButtonLabel: 'Apply',
    },
    rangeSelectorMode: 'relative-only',
    placeholder: 'Always skip',
    isValidRange: range => !isNaN((range as DateRangePickerProps.RelativeValue)?.amount) ? ({ valid: true }) : ({ valid: false, errorMessage: 'Duration cannot be empty' }),
    value: null
  }), []);

  const {
    mutateAsync: patchAlertRule,
    error: patchAlertError,
    isPending: patchAlertRuleLoading,
  } = useMutation<AlertRule, Partial<AlertRule>>({
    api: deviceManagerAPI,
    method: 'PATCH',
    url,
    notifications: [
      {
        type: 'success',
        content: `${isEditing ? 'Edited' : 'Created'} alert rule${isBulkEdit ? 's' : ''} for ${isEditingForSpecificDevices
          ? `specific device ${specificDevicesSelected[0].label}`
          : `${referringEntity} ${referringName ?? ''}`
          } successfully`,
      },
      { type: 'error' },

    ]
  });

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setDisableAllFields(true);

    const response = await patchAlertRule(!isBulkEdit ? {
      measure: measureName?.value,
      deadband_period: relativeToSeconds(deadbandValue as DateRangePickerProps.RelativeValue, 0),
      threshold_code: thresholdCodeId,
      threshold_value: +thresholdValue,
      escalation_plan: selectedEscalationPlan?.value,
      notification_frequency: {
        OPEN: relativeToSeconds(notifOpen as DateRangePickerProps.RelativeValue),
        ACKNOWLEDGED: relativeToSeconds(notifAcknowledged as DateRangePickerProps.RelativeValue),
        CLEARED: relativeToSeconds(notifClearance as DateRangePickerProps.RelativeValue),
      },
      notes: notesValue,
      status: status,
      forward: monitoringForward,
    } : {
      escalation_plan: selectedEscalationPlan?.value,
      notification_frequency: {
        OPEN: relativeToSeconds(notifOpen as DateRangePickerProps.RelativeValue),
        ACKNOWLEDGED: relativeToSeconds(notifAcknowledged as DateRangePickerProps.RelativeValue),
        CLEARED: relativeToSeconds(notifClearance as DateRangePickerProps.RelativeValue),
      },
      notes: notesValue,
      forward: monitoringForward,
    });
    rulesRefetch();
    await refetchGroups();
    setSelectedDevices([]);
    setAlertRules?.([]);
    
    setVisible(false);
    setDisableAllFields(false);
  };

  const Footer = (
    <div>
      <div style={{ paddingLeft: '3px' }}>{(alertRules && alertRules?.length > 1) ? 'Rule IDs:' : 'Rule ID:'}</div>
      {alertRules && alertRules.length > 1 ?
        (alertRules.map((rule) => (
          <span key={rule.id} style={{ padding: '0 3px 0 3px' }}>
            <Badge color='grey'>{rule.id}</Badge>
          </span>

        ))) : (<span key={alertRule?.id} style={{ padding: '0 3px 0 3px' }}>
          <Badge color='grey'>{alertRule?.id}</Badge>
        </span>)
      }
    </div>
  );

  return (
    <Modal
      onDismiss={() => {
        setVisible(false)
        refetchGroups()
        setSelectedDevices([])
      }}
      visible={visible}
      header={`${isEditing ? 'Edit' : 'Create'} Alert Rule`}
      footer={isEditing ? Footer : undefined}
      size='medium'
    >
      <form onSubmit={handleSubmit}>
        <Form
          actions={
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                variant='normal'
                formAction='none'
                onClick={() => {
                  setVisible(false)
                  refetchGroups()
                  setSelectedDevices([])
                }}
                disabled={patchAlertRuleLoading}
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                loading={patchAlertRuleLoading}
                disabled={!thresholdCodeId || !measureName}
              >
                Save
              </Button>
            </SpaceBetween>
          }
          errorText={patchAlertError?.message}
        >
          <ColumnLayout columns={1} variant='text-grid'>
            {isEditing && (
              <Alert header='You are editing an existing rule'>
                Note that your changes will not be considered on the existing incidents from this alert.
                Even if there are non resolved incidents from this rule, new occurrences will generate new incidents.
              </Alert>
            )}
            <SpaceBetween size='s' direction='vertical'>

              {isEditing && !isOnDeviceManagerPage && <ColumnLayout columns={2}>
                <Toggle
                  onChange={({ detail }) => {
                    setIsEditingForSpecificDevices(detail.checked);
                  }}
                  checked={isEditingForSpecificDevices}>
                  Edit for one device only
                </Toggle>
              </ColumnLayout>}

              {isEditingForSpecificDevices &&
                <ColumnLayout columns={1}>
                  <DeviceSelect
                    isMulti={false}
                    devicesToUse={allDevices}
                    selectedAssets={specificDevicesSelected}
                    setSelectedAssets={setSpecificDevicesSelected}
                    disabled={false} />
                </ColumnLayout>
              }

              {!isBulkEdit && isEditing && alertRule && <FormField label='Status'>
                <Toggle checked={status === AssetAlertRuleStatus.ENABLED} onChange={({ detail }) =>
                  setStatus(detail.checked ? AssetAlertRuleStatus.ENABLED : AssetAlertRuleStatus.DISABLED)
                }><AlertStatusIndicator status={status} /></Toggle>
              </FormField>}

              {!isBulkEdit && <FormField label='Measure'>
                <Select
                  selectedOption={measureName}
                  disabled={disableAllFields}
                  onChange={({ detail }) => setMeasureName(detail.selectedOption)}
                  placeholder='Choose an option'
                  loadingText='Loading measures'
                  options={measurements.map(x => ({ label: toTitleCase(x.replace(/_/gm, ' ')), value: x }))}
                  empty='No options'
                />
              </FormField>}

              {!isBulkEdit && <FormField label='Type'>
                <ThresholdPicker
                  selectedId={thresholdCodeId}
                  onChange={(value) => setThresholdCodeId(value as StateAlertConfigCode)}
                />
              </FormField>}

              <ColumnLayout columns={2}>
                {!isBulkEdit && <>
                  <FormField label='Threshold Value'>
                    <Input
                      type='number'
                      disabled={disableAllFields}
                      placeholder='0'
                      value={thresholdValue}
                      onChange={(event) => setThresholdValue(event.detail.value)}
                    />
                  </FormField>

                  <FormField label={
                    <Popover
                      dismissButton={false}
                      content='This interval allows to avoid false positive alarms, by ensuring a certain period of time has passed before triggering an alarms and creating and incident. This value will usually be related to device measurement frequency.'
                    ><b>Deadband</b></Popover>
                  }>
                    <DateRangePicker
                      onChange={({ detail }) => setDeadbandValue(detail.value)}
                      value={deadbandValue}
                      disabled={disableAllFields}
                      relativeOptions={[
                        {
                          key: "previous-10-minutes",
                          amount: 10,
                          unit: "minute",
                          type: "relative"
                        },
                        {
                          key: "previous-30-minutes",
                          amount: 30,
                          unit: "minute",
                          type: "relative"
                        },
                        {
                          key: "previous-1-hours",
                          amount: 1,
                          unit: "hour",
                          type: "relative"
                        },
                        {
                          key: "previous-2-hours",
                          amount: 2,
                          unit: "hour",
                          type: "relative"
                        }
                      ]}
                      i18nStrings={{
                        customRelativeRangeDurationLabel: 'Duration',
                        customRelativeRangeDurationPlaceholder: 'Enter duration',
                        customRelativeRangeOptionLabel: 'Custom duration',
                        customRelativeRangeUnitLabel: 'Unit of time',
                        formatRelativeRange: (e) => {
                          const n = 1 === e.amount ? e.unit : `${e.unit}s`;
                          return `${e.amount} ${n}`;
                        },
                        formatUnit: (e, n) => (1 === n ? e : `${e}s`),
                        relativeRangeSelectionHeading: 'Alarm will remain pending for',
                        clearButtonLabel: 'Disable',
                        cancelButtonLabel: 'Cancel',
                        applyButtonLabel: 'Apply',
                      }}
                      rangeSelectorMode='relative-only'
                      placeholder='Disabled'
                      isValidRange={range => !isNaN((range as DateRangePickerProps.RelativeValue)?.amount) ? ({ valid: true }) : ({ valid: false, errorMessage: 'Duration cannot be empty' })}
                    />
                  </FormField>
                </>}
                <FormField label='Escalation Plan'>
                  <Select
                    selectedOption={selectedEscalationPlan || null}
                    disabled={disableAllFields}
                    onChange={({ detail }) => setSelectedEscalationPlan(detail.selectedOption)}
                    loadingText='Loading escalation plans'
                    options={escalationPlansList}
                    statusType={escalationPlansList.length === 0 ? 'loading' : 'finished'}
                    errorText='Error fetching escalation plans.'
                    empty='No escalations plans'
                  />
                </FormField>

                <FormField label={
                  <Popover
                    dismissButton={false}
                    content='This option will forward the incident to the central monitoring system and only intended for specific use cases. Please contact the product team for more information.'
                  ><b>Forward to Central Monitoring</b></Popover>
                }>
                  <Toggle
                    onChange={({ detail }) => setMonitoringForward(detail.checked)}
                    checked={monitoringForward}
                    disabled={disableAllFields}>
                    {monitoringForward ? 'Enabled' : 'Disabled'}
                  </Toggle>
                </FormField>
              </ColumnLayout>

              <FormField label={<span>Notes <i>- optional</i></span>}>
                <Textarea
                  disabled={disableAllFields}
                  value={notesValue}
                  onChange={(event) => setNotesValue(event.detail.value)}
                />
              </FormField>

              <ExpandableSection headerText="Notification Frequencies">
                <SpaceBetween size='xs' direction='vertical'>
                  <span>Configure specific notification intervals to skip between notifications of the same incident,
                    to reduce the amount of received messages.</span>
                  <FormField label={
                    <span>
                      When incident is &nbsp;
                      <IncidentStatusBadge status={IncidentStatus.OPEN} />
                      &nbsp; or &nbsp;
                      <IncidentStatusBadge status={IncidentStatus.FORWARDED} />
                    </span>

                  }>
                    <DateRangePicker
                      {...notificationFrequencyProps}
                      onChange={({ detail }) => setNotifOpen(detail.value)}
                      value={notifOpen}
                      disabled={disableAllFields}
                    />
                  </FormField>
                  <FormField label={
                    <span>When incident is &nbsp;<IncidentStatusBadge status={IncidentStatus.ACKNOWLEDGED} /></span>
                  }>
                    <DateRangePicker
                      {...notificationFrequencyProps}
                      onChange={({ detail }) => setNotifAcknowledged(detail.value)}
                      value={notifAcknowledged}
                      disabled={disableAllFields}
                    />
                  </FormField>
                  <FormField label={
                    <span>When incident is &nbsp;<IncidentStatusBadge status={IncidentStatus.CLEARED} /></span>
                  }>
                    <DateRangePicker
                      {...notificationFrequencyProps}
                      onChange={({ detail }) => setNotifClearance(detail.value)}
                      value={notifClearance}
                      disabled={disableAllFields}
                    />
                  </FormField>
                </SpaceBetween>
              </ExpandableSection>
            </SpaceBetween>
          </ColumnLayout>
        </Form>
      </form >
    </Modal >
  );
};

export default CreateAlertModal;
