import { Link } from "@cloudscape-design/components";
import { Tooltip } from "@mui/material";

import DeviceStateBadge from "components/device-state-badge/DeviceStateBadge";
import { Asset, DeviceState } from "types/custom";

export const getContentDisplay = (countAlertRules: boolean) => {
    const contentDisplay = [
        { id: "name", visible: true },
        { id: "friendlyName", visible: true },
        { id: "deviceState", visible: true },
        { id: "deviceTypeId", visible: true },
        { id: "lastUpdated", visible: true },
        { id: "physicalDeviceId", visible: false },
        { id: "description", visible: false },
        { id: "lookupId", visible: false },
        { id: "invoiceId", visible: false },
        { id: "friendlyCurrentPath", visible: false },
    ];

    if (countAlertRules) {
       contentDisplay.splice(1, 0, { id: "rules", visible: true });
    }

    return contentDisplay;
}
export const getContentDisplayPreferences = (countAlertRules: boolean) => {
    const contentDisplayPreference = [     
        { id: "name", label: "Name" },
        { id: "friendlyName", label: "Friendly Name" },
        { id: "deviceState", label: "Device State" },
        { id: "deviceTypeId", label: "Device Type" },
        { id: "lastUpdated", label: "Last Updated" },
        { id: "physicalDeviceId", label: "Physical Device ID" },
        { id: "description", label: "Description" },
        { id: "lookupId", label: "Lookup ID" },
        { id: "invoiceId", label: "Invoice ID" },
        { id: "friendlyCurrentPath", label: "Friendly Current Path" },]
    
    if (countAlertRules) {
       contentDisplayPreference.splice(1, 0, { id: "rules", label: "Device Rules" });
    }

    return contentDisplayPreference;
}

export const getColumnDefinitions = (countAlertRules: boolean, setSelectedDevices: (asset: Asset[]) => void) => {
    const baseDefinitions = getColumnDefinitionsBase(setSelectedDevices);
    if (countAlertRules)
    {
        return [{
            id: 'rules',
            header: 'Device Rules',
            cell: (item: Asset) => {
                const title = !!item.numberOfAlertRules ?
                `The device has ${item.numberOfAlertRules} device specific alert rule${item.numberOfAlertRules === 1? "" : 's'}.`
                : 'The device has no device specific alert rules';
                return <Tooltip title={title}><div>{item.numberOfAlertRules}</div></Tooltip>;
            },
        },
        ...baseDefinitions];}
    return baseDefinitions;
}

export const getColumnDefinitionsBase = (setSelectedDevices: (asset: Asset[]) => void) => [
    {
        id: 'name',
        header: 'Name',
        cell: (item: Asset) => (<Link onFollow={() => {setSelectedDevices([item])}}>
          {item.name || '-'}
        </Link>),
        sortingField: 'name',
    },
    {
        id: 'friendlyName',
        header: 'Friendly Name',
        cell: (item: Asset) => (<Link onFollow={() => {setSelectedDevices([item])}}>
          {item.friendlyName || '-'}
        </Link>),
        sortingField: 'friendlyName',
    },
    {
        id: 'deviceState',
        header: 'State',
        cell: (item: Asset) =>(<DeviceStateBadge state={item.deviceState}/>),
        sortingField: 'deviceState',
    },
    {
        id: 'deviceTypeId',
        header: 'Device Type',
        cell: (item: Asset) => item.deviceTypeId,
        sortingField: 'deviceTypeId',
    },
    {
        id: 'physicalDeviceId',
        header: 'Physical Device ID',
        cell: (item: Asset) => item.physicalDeviceId,
        sortingField: 'physicalDeviceId',
    },
    {
        id: 'description',
        header: 'Description',
        cell: (item: Asset) => item.description,
        sortingField: 'description',
    },
    {
        id: 'lookupId',
        header: 'Lookup ID',
        cell: (item: Asset) => item.lookupId,
        sortingField: 'lookupId',
    },
    {
        id: 'invoiceId',
        header: 'Invoice ID',
        cell: (item: Asset) => item.invoiceId,
        sortingField: 'invoiceId',
    },
    {
        id: 'friendlyCurrentPath',
        header: 'Friendly Current Path',
        cell: (item: Asset) => item.friendlyCurrentPath,
        sortingField: 'friendlyCurrentPath',
    },
    {
        id: 'lastUpdated',
        header: 'Last Updated',
        cell: (item: Asset) => {
            const date = new Date(item.updatedAt * 1000).toLocaleString();
            return date === 'Invalid Date' ? 'Not recorded' : date;
        },
        sortingField: 'lastUpdated',
    },
];

export const buttonDropdownItems = (selectedDevices: Asset[]) => {
    const isEnabledFor = (enabledStates: DeviceState[], allowsBulk: boolean = false) =>
        selectedDevices.length > 0 && 
        selectedDevices.every(device => enabledStates.includes(device.deviceState)) &&
        (allowsBulk || selectedDevices.length === 1);

    return [
        {
            text: 'Install',
            id: 'install',
            disabled: !isEnabledFor([DeviceState.disabled, DeviceState.inService], true),
        },
        {
            text: 'Update',
            id: 'edit',
            disabled: !isEnabledFor([DeviceState.disabled, DeviceState.inService], false),
        },
        {
            text: 'Disable',
            id: 'disable',
            disabled: !isEnabledFor([DeviceState.inService], true),
        },
        {
            text: 'Delete',
            id: 'delete',
            disabled: !isEnabledFor([DeviceState.disabled, DeviceState.inService], false),
        },
    ];
};

