import { ContentLayout, Header } from '@cloudscape-design/components';

import AlertRulesManagerProvider from 'providers/AlertRulesManagerProvider';
import colors from 'theme/colors';

import AlertRulesManagerPage from './alert-rules-manager-page';

const AlertRulesManager = () => {
    return (
      <AlertRulesManagerProvider>
        <ContentLayout defaultPadding headerBackgroundStyle={colors.accentGreen2} header={<Header variant="h1">Alert Rules Manager</Header>}>
          <AlertRulesManagerPage />
        </ContentLayout>
      </AlertRulesManagerProvider>
    );
};

export default AlertRulesManager;
