import { createContext, useContext, useEffect, useState } from 'react';
import { Box, ColumnLayout } from '@cloudscape-design/components';

import RolesTable from 'components/role-manager/RolesTable';
import RolesTabs from 'components/role-manager/RolesTabs';
import { API_URL_PATH_UM_ROLES } from 'constants/urls';
import { UserRolesProps } from 'types/custom';
import { userManagerAPI } from 'api';
import useFetch from 'hooks/useFetch';

const UserManagerContext = createContext({
    allItems: [] as any[],
    isLoading: false,
    isFetching: false,
    setSelectedItem: (state: UserRolesProps[]) => { },
    selectedItem: [] as UserRolesProps[] | undefined,
    refetch: () => { },
    activeTabId: '',
    setActiveTabId: (state: string) => { },
});

export const useUserManagerContext = () => useContext(UserManagerContext);

const RolesListPage = () => {
    const [selectedItem, setSelectedItem] = useState<UserRolesProps[]>([]);
    const [allItems, setAllItems] = useState<UserRolesProps[]>([]);
    const [activeTabId, setActiveTabId] = useState<string>('details');

    const {
        data,
        isLoading,
        isFetching,
        status,
        refetch } = useFetch<{ items: UserRolesProps[] }>({
            axiosInstance: userManagerAPI,
            url: API_URL_PATH_UM_ROLES,
            key: 'roles',
        });

    useEffect(() => {
        if (status === 'success') {
            setAllItems(data?.items || []);
        }
    }, [status, data]);

    return (
      <UserManagerContext.Provider
            value={{
                isLoading,
                isFetching,
                allItems,
                setSelectedItem,
                selectedItem,
                refetch,
                activeTabId,
                setActiveTabId,
            }}
        >
        <ColumnLayout variant='text-grid'>
          <RolesTable />
        </ColumnLayout>

        {data && selectedItem![0]?.name !== undefined && (
          <>
            <Box margin={{ top: 'xxxl' }}></Box>
            <ColumnLayout variant='text-grid'>
              <RolesTabs />
            </ColumnLayout>
          </>
            )}
      </UserManagerContext.Provider>
    );
};

export default RolesListPage;
